import { httpAcounts } from "../../config/http";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";
import Encrypt from "../../utils/Encript";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const actionTypes = {
  CHANGE: "AUTH_CHANGE",
  SUCCESS: "AUTH_SUCCESS",
};

export const change = (payload) => ({
  type: actionTypes.CHANGE,
  payload,
});

export const success = (payload) => ({
  type: actionTypes.SUCCESS,
  payload,
});
export const setUserToken = ({token, refreshToken, expirationDate, username, serverDateTime, serverUTCOffset}) => (dispatch) => {
  const tk = Encrypt(token);
  cookies.set("userName", username, { path: "/" });
  cookies.set("access_token", tk, { path: "/" });
  cookies.set("refresh_token", refreshToken, { path: "/" });
  cookies.set("expiration_date", expirationDate, { path: "/" });
  cookies.set("techUser", true, { path: "/" });
  cookies.set("serverDateTime", serverDateTime, { path: "/" });
  cookies.set("serverUTCOffset", serverUTCOffset, { path: "/" });
  
  dispatch(
    change({
      email: "",
      password: "",
    })
  );
  dispatch(success(true));
};

export const login = (credentials) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Autenticando...",
    })
  );

  return httpAcounts
    .post("/login", {
      // grant_type: "password",
      // client_id: 2,
      // client_secret: "7NYfemhAyJpIPaEYfo3DADujxTSwkoPu9dwoygmL",
      username: credentials.email,
      password: credentials.password,
    })
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res.status === 200) {
        if (res.data.token) {
          // console.log(res.data)
          dispatch(setUserToken(res.data));
        }
      }
    })
    .catch((err) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (typeof err.response !== "undefined") {
        if (err.response.status === 401 || err.response.status === 400) {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              time: 3000,
              msg: "E-mail ou Senha incorretos",
            })
          );
        }
      } else {
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            time: 3000,
            msg: "Erro ao conectar com o Servidor",
          })
        );
      }
    });
};

export const Logout = (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Saindo...",
    })
  );
  return httpAcounts
    .post("/api/auth/logout")
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
          msg: "",
        })
      );
      cookies.remove("access_token", { path: "/" });
      cookies.remove("refresh_token", { path: "/" });
      cookies.remove("expiration_date", { path: "/" });
      cookies.remove("empresa", { path: "/" });
      window.location.href = "/login";
    })
    .catch((err) => {
      cookies.remove("access_token", { path: "/" });
      cookies.remove("refresh_token", { path: "/" });
      cookies.remove("expiration_date", { path: "/" });
      cookies.remove("userName", { path: "/" });
      cookies.remove("empresaid", { path: "/" });
      cookies.remove("empresa", { path: "/" });
      cookies.remove("serverUTCOffset", { path: "/" });
      window.location.href = "/login";
    });
};
