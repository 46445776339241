import { actionTypes } from "../Actions/romaneio.action";

const initialState = {
  romaneios: {
    data: [], // Lista de romaneios
  },
  romaneio: {}, // Romaneio individual, caso necessário
  success: false,
  error: {},
};

export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      // Atualiza os dados de romaneios
      return {
        ...state,
        romaneios: {
          ...state.romaneios,
          data: payLoad, // Atualiza os dados da lista
        },
      };
    case actionTypes.DESTROY:
      // Remove o romaneio da lista com base no ID
      return {
        ...state,
        romaneios: {
          ...state.romaneios,
          data: state.romaneios.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      // Atualiza o romaneio individual
      return {
        ...state,
        romaneio: {
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
